import React, { useEffect, useState } from "react";
import "./App.css";
import "./styles/navigation.scss";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styledb from "styled-components";
import { create } from "ipfs-http-client";
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faDiscord, faInstagram } from '@fortawesome/free-brands-svg-icons';
import openSea from './images/openSea.png';
import chickengif from './images/chickengif.gif';
import connectmetamask from './images/connect.png';
import minting from './images/minting.gif';
import mintone from './images/mintone.png';
import choosetext from './images/choosetext.png';
import mintinglive from './images/mintinglive.png';
import rarity from './images/rarity.png';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import alex from './images/alex.png';
import warwick from './images/warwick.png';
import lugg from './images/lugg.png';
import Grid from '@mui/material/Grid';

export const StyledButton = styledb.button`
  padding: 8px;
`;

export const styledModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: "65%",
  maxWidth: 800,
  width: "95%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  overflow: "scroll",
  p: 4,
};

export const styledModal2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: 800,
  maxWidth: 1200,
  width: "95%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  overflow: "hidden",
  p: 4,
};

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("");
  const [claimingNft, setClaimingNft] = useState(false);
  const [xamount, setXAmount] = useState(1);
  const [expanded, setExpanded] = React.useState('panel1');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);


  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const claimNFTs = (_amount) => {
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        gasLimit: (160000 * _amount).toString(), //Rinkeby 1x185000 //Ether 1x227991 3x190733 10x177692 20x174898
        to: "0xF317841aCB3Cbff644478920f68efb28C8F127Be",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((0.07 * _amount).toString(), "ether"),
    }).once("error", (err) => {
      console.log(err);
      setFeedback("Something happened, please try again.");
      setClaimingNft(false);
    }).then((receipt) => {
      setFeedback("You have successfully minted from the CCCH! Check out your OpenSea account to view it!");
      setClaimingNft(false);
      dispatch(fetchData(blockchain.account));
    });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <header style={{padding: 20, alignItems: "center", justifyContent: "center", display: "flex"}}>
        <Button style={{backgroundColor: "transparent", boxShadow: "none", color: "white", fontWeight: "bold"}} rel="noopener noreferrer" target="_blank" href="https://twitter.com/CryptoChickenCH" variant="contained">
        <FontAwesomeIcon style={{fontSize: 50}} icon={faTwitter} />
        </Button>
        <Button style={{backgroundColor: "transparent", boxShadow: "none", color: "white", marginLeft: "1rem", fontWeight: "bold"}} rel="noopener noreferrer" target="_blank" href="https://instagram.com/cryptochickenclubhouse" variant="contained">
        <FontAwesomeIcon style={{fontSize: 50}} icon={faInstagram} />
        </Button>
        <Button style={{backgroundColor: "transparent", boxShadow: "none", color: "white", marginLeft: "1rem", fontWeight: "bold"}} rel="noopener noreferrer" target="_blank" href="https://discord.gg/F8tbQDfCXr" variant="contained">
        <FontAwesomeIcon style={{fontSize: 50}} icon={faDiscord} />
        </Button>
        <Button style={{backgroundColor: "transparent", boxShadow: "none", color: "white", marginLeft: "1rem", fontWeight: "bold"}} rel="noopener noreferrer" target="_blank" href="https://opensea.io/collection/crypto-chicken-club-house" variant="contained">
        <img src={openSea} style={{height: 50}} alt="openSea" />
        </Button>
		</header>

    <div style={{alignItems: "center", justifyContent: "center", textAlign: "center", display: "flex", paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20}}>
      <span className="title">
        Crypto Chicken Club House
      </span>
    </div>
    <div style={{alignItems: "center", justifyContent: "center", textAlign: "center", display: "flex", paddingTop: 10, paddingBottom: 30, paddingLeft: 20, paddingRight: 20}}>
      <span className="title2">
        Mint pass + Play-2-Earn + $CBKZ token
      </span>
    </div>
    <div style={{height: 450, alignItems: "center", justifyContent: "center", display: "flex", marginBottom: 50, paddingLeft: 20, paddingRight: 20}}>
      <span>
        <img src={chickengif} style={{width: "100%", maxWidth: 400, borderRadius: 3}} alt="Crypto" />
      </span>
    </div>

    <div style={{height: 600, alignItems: "center", justifyContent: "center", display: "flex", backgroundColor: "#ffdd4a", flexDirection: "column", paddingTop: 250, paddingBottom: 250, paddingRight: 20, paddingLeft: 20}}>
      {blockchain.account === "" ||
        blockchain.smartContract === null ? (
        <s.Container flex={1} ai={"center"} jc={"center"}>
          <img src={mintinglive} style={{height: 300}} alt="Minting is live!" />
          <s.SpacerSmall />
          <Button style={{backgroundColor: "white", fontFamily: "cursive", boxShadow: "none", color: "black", padding: 15, fontWeight: "bold", width: "100%", maxWidth: 400, cursor: "default"}}
            onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
              getData();
            }}
          >
            <img src={connectmetamask} style={{height: "100%", width: "71%"}} alt="Connect to Metamask" />
          </Button>
          <s.SpacerSmall />
          {blockchain.errorMsg !== "" ? (
            <s.TextDescription>{blockchain.errorMsg}</s.TextDescription>
          ) : null}
        </s.Container>
      ) : (
        <s.Container flex={1} ai={"center"} jc={"center"} style={{ padding: 24 }}>
          <img src={choosetext} style={{height: 200}} alt="Choose how many you want to mint!" />
          <s.SpacerSmall />
          <div style={{display: "flex", flexDirection: "row"}}>
          <Button style={{backgroundColor: "white", fontFamily: "cursive", boxShadow: "none", color: "black", padding: 15, fontWeight: "bold", width: 50, opacity: 1, cursor: "default"}}
            disabled={claimingNft ? 1 : 0}
            onClick={(e) => {
              e.preventDefault();
              setFeedback("");
              getData();
              if (xamount > 1){
                setXAmount(xamount - 1);
              }
            }}
          >
            -
          </Button>
          <s.SpacerSmall />
          <Button style={{backgroundColor: "white", fontFamily: "cursive", boxShadow: "none", color: "black", padding: 15, fontWeight: "bold", width: 50, opacity: 1, cursor: "default"}} disabled={true}>
            {xamount}
          </Button>
          <s.SpacerSmall />
          <Button style={{backgroundColor: "white", fontFamily: "cursive", boxShadow: "none", color: "black", padding: 15, fontWeight: "bold", width: 50, opacity: 1, cursor: "default"}}
            disabled={claimingNft ? 1 : 0}
            onClick={(e) => {
              e.preventDefault();
              setFeedback("");
              getData();
              if (xamount < 5){
                setXAmount(xamount + 1);
              }
            }}
          >
            +
          </Button>
          </div>
          <s.SpacerSmall />
          <Button style={{backgroundColor: "white", fontFamily: "cursive", boxShadow: "none", color: "black", padding: 15, fontWeight: "bold", width: 400, opacity: 1, cursor: "default"}}
            disabled={claimingNft ? 1 : 0}
            onClick={(e) => {
              e.preventDefault();
              claimNFTs(xamount);
              setFeedback("");
              getData();
            }}
          >
            {claimingNft ? <img src={minting} style={{height: 20}} alt="Minting..." /> : <img src={mintone} style={{height: 20}} alt="Mint 1 NFT" />}
          </Button>
          <s.SpacerSmall />
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center" }}>{feedback}</s.TextDescription>
          <s.SpacerSmall />
        </s.Container>
        
      )}
      </div>

      <div style={{height: "100%", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", marginBottom: 50, marginTop: 50}}>
      <div className="free" style={{color: "white", fontSize: 75}}>FAQs</div>
      <div style={{padding: "20px 20px"}}>
      <Accordion style={{borderTopLeftRadius: 10, borderTopRightRadius: 10, width: "100%", maxWidth: 500}} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary style={{borderTopLeftRadius: 10, borderTopRightRadius: 10, backgroundColor: "white"}} aria-controls="panel1d-content" id="panel1d-header">
          <Typography style={{fontFamily: "ak50", fontSize: 20}}>What is the Crypto Chicken Club House?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "", fontSize: 16}}>
            The Crypto Chicken Club House (CCCH) is a collective group of ERC-721 tokens on the Ethereum blockchain which has many long-term holding benefits. These
            include things such as a mint pass, access to our Play-2-Earn game in development, and a $CBKZ (C-Bucks) token that will be utilized in multiple ways.
            <br/>
            <br/>
            <a href="https://opensea.io/collection/crypto-chicken-club-house" rel="noreferrer" target="_blank">
            Check out our official OpenSea collection here
            </a>.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{width: "100%", maxWidth: 500}}expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary style={{backgroundColor: "white"}} aria-controls="panel2d-content" id="panel2d-header">
          <Typography style={{fontFamily: "ak50", fontSize: 20}}>NFT faqs and details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "", fontSize: 16, padding: 5}}>
            &#8226; This project is on the Ethereum blockchain.
          </Typography>
          <Typography style={{fontFamily: "", fontSize: 16, padding: 5}}>
            &#8226; The mint price of each chicken is 0.07 ETH + gas fees.
          </Typography>
          <Typography style={{fontFamily: "", fontSize: 16, padding: 5}}>
            &#8226; No wait for the reveal, you'll be able to see what chicken you got right away.
          </Typography>
          <Typography style={{fontFamily: "", fontSize: 16, padding: 5}}>
            &#8226; Only 10,000 Crypto Chickens will ever be apart of this Club House so get in quick!
          </Typography>
          <Typography style={{fontFamily: "", fontSize: 16, padding: 5}}>
            &#8226; Rarity chart:
            <img src={rarity} onClick={handleOpen2} style={{width: "100%", cursor: "pointer"}} alt="Rarity" />
            <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open2}
            disableAutoFocus={true}
            onClose={handleClose2}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
          <Fade in={open2}>
            <Box sx={styledModal2}>
              <Typography>
                <img src={rarity} style={{width: "100%"}} alt="Rarity" />
              </Typography>
            </Box>
          </Fade>
          </Modal>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{width: "100%", maxWidth: 500}}expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary style={{backgroundColor: "white"}} aria-controls="panel3d-content" id="panel3d-header">
          <Typography style={{fontFamily: "ak50", fontSize: 20}}>The CCCH backstory</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "", fontSize: 16}}>
          <Button style={{fontFamily: "", fontSize: 16, textTransform: "none", padding: 0, margin: 0}} onClick={handleOpen}><u>Press here to read...</u></Button>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            disableAutoFocus={true}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
          <Fade in={open}>
            <Box sx={styledModal}>
              <Typography style={{fontWeight: "bold"}} id="transition-modal-title" variant="h6" component="h2">
                The theory behind it all
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              It all started back around the spring of 1967 in a small town called Winslow, 
              and in that small town was a farmer that goes by the name of Hanns. Now, Hanns wasn't 
              your usual type of farmer, he didn't plant seeds to grow crops in the summer, nor did 
              he raise animals for produce. Let's just say that he is an "experimental" farmer, who 
              creates and breeds his own things, and in this instance, it was chickens.
              <br/><br/>
              One afternoon late in that spring, there he was (Hanns), in his rugged tin shed manifesting 
              and engineering a "special" device that was about to change everything. He called it the Brutschrank, 
              this was a mechanism that he was trying to create which alters DNA in a very specific way. Hanns always 
              thought the human race was badly designed and wanted to create something that helps make a better 
              version of ourselves. The way to use this machine is by offering something you want to 
              change, and for his test subjects, he used his chickens.
              <br/><br/>
              This was all good and well until one night, he was very close to the final touches on 
              the Brutschrank, he wanted to keep working on it but realized he would be better off 
              sleeping and finishing it in the morning. He put down his tools on top of his workbench, 
              closes the hatchet door where he keeps all his chickens, and stumbles off to bed. There 
              was only one thing he forgot to do, and that was to lock the hatchet door.
              <br/><br/>
              Later, in the middle of the night, a massive gust of wind was sent through the town of 
              Winslow, rattling his small tin shed and swinging the hatchet door wide open. The slam 
              from the hatchet was destructive enough to break his bench, which lead to all his tools 
              falling off and landing on top of the controls of the Brutschrank. This triggered multiple 
              buttons and levers, messing with the specific setup he initially had, making each DNA 
              completely random yet unique if something was to be put in there. Everything seemed to 
              have stopped falling and the damage didn't look too bad as Hanns could easily fix the 
              bench, and reverse the accidental changes the following morning.
              <br/><br/>
              The shed stood still except one bolt Hanns had not used yet, barely clinging onto the 
              broken bench above the controls, it falls. The bolt lands on a button that begins the 
              entire process of the Brutschrank. All the chickens he owned, 10,000 in fact, were sent through the hatchet and one by 
              one and were mutated, coming out the other side with completely new DNA, each different 
              from the next. Since the Brutschrank was not yet completed, once the last 
              chicken went through, the machine blew up, spitting out parts everywhere. This woke Hanns and the entire town of Winslow.
              <br/><br/>
              The fire department was on the scene just like everyone else. Once the fire was settled, 
              a destroyed Brutschrank was found, with a completely shattered Hanns that was one sleep 
              away from his groundbreaking moment, with no idea what just happened. His chicken farm 
              was completely empty, with not one chicken insight?
              <br/><br/>
              Hanns was arrested on the scene and sentenced to life in prison as the judges saw this as 
              an act of terror and the creation of prohibited weaponry. After many years since his sentence, there was talk 
              about these chickens being found and sold on the Ethereum blockchain, and are now owned by 
              members of an elite community called the Crypto Chicken Club House. The rumors were never confirmed, but insider information shows
              that it might actually be the case.
              </Typography>
            </Box>
          </Fade>
      </Modal>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{width: "100%", maxWidth: 500}} expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary style={{backgroundColor: "white"}} aria-controls="panel9d-content" id="panel9d-header">
          <Typography style={{fontFamily: "ak50", fontSize: 20}}>How do I found out more information?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "", fontSize: 16}}>
            If you join our Discord, you will find loads more information about the project, major announcements, community givebacks and more! It will showcase everything we are 
            doing, planning to do, and most importantly, you will have a say in what the team does next.&nbsp;<a href="https://discord.gg/F8tbQDfCXr" rel="noreferrer" target="_blank">
            Join our discord here!
            </a>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, width: "100%", maxWidth: 500}}expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, backgroundColor: "white"}} aria-controls="panel5d-content" id="panel5d-header">
          <Typography style={{fontFamily: "ak50", fontSize: 20}}>I'm new to NFT's, help?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "", fontSize: 16}}>
          <div style={{fontWeight: "bold", marginBottom: 5}}>&#8226; What's an NFT?</div>NFT stands for non-fungible token, which basically means that it's a one-of-a-kind 
          digital asset that belongs to you and you only. The most popular NFTs right now 
          include artwork and music, but can also include videos and even tweets.
          </Typography>
          <br/>
          <Typography style={{fontFamily: "", fontSize: 16}}>
          <div style={{fontWeight: "bold", marginBottom: 5}}>&#8226; What does minting mean?</div>When an NFT project releases their NFTs for the first time to the general public there is an initial phase called minting.
          During the minting process, you do not get to choose your digital art. It is 
          assigned to you randomly, and every piece of art has the same price. Naturally, 
          the cost is much lower than buying it on the marketplace such as OpenSea.
          </Typography>
          <br/>
          <Typography style={{fontFamily: "", fontSize: 16}}>
          <div style={{fontWeight: "bold", marginBottom: 5}}>&#8226; What is Metamask?</div>MetaMask is a browser extension that acts as both a wallet and a way for you to interact with 
          the Ethereum network. That's why MetaMask comes up a lot when looking into new projects as many 
          are launched on the Ethereum network and only accept ETH during the minting stage. For more information
          check out their&nbsp;
          <a href="https://metamask.io/" rel="noreferrer" target="_blank">
            website here
          </a>
          .
          </Typography>
          <br/>
          <Typography style={{fontFamily: "", fontSize: 16}}>
          <div style={{fontWeight: "bold", marginBottom: 5}}>&#8226; How do I buy one?</div>1. Download Metamask:<br/>Start by downloading MetaMask as an app to your mobile device or install the browser extension.
          Any other questions you may have regarding Metamask can be found&nbsp;
          <a href="https://metamask.io/faqs/" rel="noreferrer" target="_blank">found here
          </a>
          .<br/><br/>
          2. Buy ETH (Ethereum):<br/>Buy ETH on an exchange (i.e. Binance, Kraken, Coinbase etc.) and transfer the tokens to your MetaMask wallet adress. Tip: You may need a little more than 0.07 ETH because of gas fees.
          <br/><br/>
          3. Connect your wallet & mint:<br/>If you've downloaded the mobile app, open Metamask and go to our website through their browser located within the app, then "Connect to Metamask" under adopt a chicken and follow the prompts. Otherwise if you have the
          browser extension, you can click "Connect to Metamask" on our site under adopt a chicken and it will prompt you to log into your Metamask if you haven't already, and then follow the prompts.
          </Typography>
        </AccordionDetails>
      </Accordion>
      </div>
      </div>

      <div style={{height: "100%", width: "100%", alignItems: "center", justifyContent: "center", backgroundColor: "#ffdd4a", paddingLeft: 20, paddingRight: 20, textAlign: "center", paddingTop: 100, paddingBottom: 100}}>
      <span className="free" style={{color: "black", fontSize: 75, marginBottom: 20}}>
          Meet the team
        </span>
        <Grid 
        container
        direction="row"
        justifyContent="center"
        alignItems="center">
          <span className="free" style={{color: "black", fontSize: 40, marginTop: 10, alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column"}}>
          <a href="https://twitter.com/my3rg" rel="noreferrer" target="_blank">
            <img src={alex} style={{width: 300, padding: 10, cursor: "pointer"}} alt="Alex"/>
          </a>
          CTO
          </span>
          <span className="free" style={{color: "black", fontSize: 40, marginTop: 10, alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column"}}>
          <a href="https://twitter.com/wockroach" rel="noreferrer" target="_blank"> 
            <img src={warwick} style={{width: 300, padding: 10, cursor: "pointer"}} alt="Warwick" />
          </a>
          Artist
          </span>
          <span className="free" style={{color: "black", fontSize: 40, marginTop: 10, alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column"}}>
          <a href="https://twitter.com/lugg97" rel="noreferrer" target="_blank"> 
            <img src={lugg} style={{width: 300, padding: 10, cursor: "pointer"}} alt="Lugg" />
          </a>
          CMO
          </span>
        </Grid>
      </div>

      <div style={{height: 150, alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column"}}>
        <span className="free" style={{color: "white", fontSize: 30, paddingTop: 0, alignItems: "center", justifyContent: "center", display: "flex"}}>
        <Button style={{backgroundColor: "transparent", boxShadow: "none", color: "white", fontWeight: "bold"}} rel="noopener noreferrer" target="_blank" href="https://twitter.com/CryptoChickenCH" variant="contained">
        <FontAwesomeIcon style={{fontSize: 50}} icon={faTwitter} />
        </Button>
        <Button style={{backgroundColor: "transparent", boxShadow: "none", color: "white", marginLeft: "1rem", fontWeight: "bold"}} rel="noopener noreferrer" target="_blank" href="https://instagram.com/cryptochickenclubhouse" variant="contained">
        <FontAwesomeIcon style={{fontSize: 50}} icon={faInstagram} />
        </Button>
        <Button style={{backgroundColor: "transparent", boxShadow: "none", color: "white", marginLeft: "1rem", fontWeight: "bold"}} rel="noopener noreferrer" target="_blank" href="https://discord.gg/F8tbQDfCXr" variant="contained">
        <FontAwesomeIcon style={{fontSize: 50}} icon={faDiscord} />
        </Button>
        <Button style={{backgroundColor: "transparent", boxShadow: "none", color: "white", marginLeft: "1rem", fontWeight: "bold"}} rel="noopener noreferrer" target="_blank" href="https://opensea.io/collection/crypto-chicken-club-house" variant="contained">
        <img src={openSea} style={{height: 50}} alt="openSea" />
        </Button>
        </span>
      </div>
    </s.Screen>
  );
}

export default App;
